import {
    buildEnumValueConfig,
    buildProperty,
    buildSchema,
    getNavigationFrom
} from "@camberi/firecms";
import { locales } from "./products_schema";
import CustomShapedArrayField
    from "../custom_shaped_array/CustomShapedArrayField";
import CustomShapedArrayPreview
    from "../custom_shaped_array/CustomShapedArrayPreview";

const relaxedStatus = new Map([
    ["-3", buildEnumValueConfig({
        label: "Very tense",
        color: "redDarker"
    })],
    ["-2", buildEnumValueConfig({
        label: "Medium tense",
        color: "redLight"
    })],
    ["-1", buildEnumValueConfig({
        label: "Lightly tense",
        color: "redLighter"
    })],
    ["0", buildEnumValueConfig({
        label: "Normal",
        color: "grayLight"
    })],
    ["1", buildEnumValueConfig({
        label: "Lightly relaxed",
        color: "blueLighter"
    })],
    ["2", buildEnumValueConfig({
        label: "Medium relaxed",
        color: "blueLight"
    })],
    ["3", buildEnumValueConfig({
        label: "Very relaxed",
        color: "blueDarker"
    })]
]);

export const roomEntitySchema = buildSchema({
    customId: true,
    name: "room",
    onPreSave: ({
                    schema,
                    collectionPath,
                    id,
                    values,
                    status,
                    context
                }) => {
        return getNavigationFrom({
            path: `${collectionPath}/${id}`,
            context
        }).then((navigation) => {
            console.log("navigation", navigation);
            return values;
        });
    },
    properties: {
        /*string_array: {
            title: "String array",
            dataType: "array",
            of: {
                dataType: "string"
            }
        },*/
        room_name: {
            title: "Room Name",
            dataType: "string",
            validation: {
                unique: true,
                required: true
            }
        },
        /*content: {
            title: "Content",
            description: "Example of a complex array with multiple properties as children",
            validation: { required: true },
            dataType: "array",
            columnWidth: 450,
            oneOf: {
                properties: {
                    title: {
                        title: "Title",
                        dataType: "string"
                    },
                    text: {
                        dataType: "string",
                        title: "Text",
                        config: {
                            markdown: true
                        }
                    }
                }
            }
        },*/
        artist_display: {
            title: "Artist Display",
            dataType: "string",
            validation: {
                unique: true
            }
        },
        empty_string: {
            title: "Empty String",
            dataType: "string",
            validation: {
                unique: true
            }
        },
        model_ar: {
            title: "Model (AR)",
            dataType: "string",
            validation: {
                unique: true
            }
        },
        model_vr: {
            title: "Model (VR)",
            dataType: "string",
            validation: {
                unique: true
            }
        },
        model_gltf: {
            title: "Model (gltf)",
            dataType: "string",
            validation: {
                unique: true
            }
        },
        poster_img:{
            title: "Poster Image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "imgtest",
                    acceptedFiles: ["image/*"]
                }
            }
        },
        /*nfts: {
            title: "NFTs",
            dataType: "reference",
            collectionPath: "nfts",
            previewProperties: ["tokenId", "service"]
        },*/
        /*product: {
            title: "Product",
            dataType: "reference",
            collectionPath: "products",
            previewProperties: ["name", "main_image"]
        },*/
        nfts: {
            title: "NFTs",
            dataType: "array",
            of: {
                dataType: "reference",
                collectionPath: "nfts",
                previewProperties: ["tokenId", "service"]
            }
        },
        artists: {
            title: "Artists",
            dataType: "array",
            of: {
                dataType: "reference",
                collectionPath: "artists",
                previewProperties: ["first_name", "last_name"]
            }
        },
        title: ({ values, entityId }) => {
            if (values?.available_locales && Array.isArray(values.available_locales) && values.available_locales.includes("de"))
                return ({
                    dataType: "string",
                    title: "Title disabled",
                    disabled: {
                        clearOnDisabled: true,
                        tooltip: "Disabled because German is selected"
                    }
                });
            return ({
                dataType: "string",
                title: "Title"
            });
        },
        description: {
            title: "Description",
            dataType: "string",
            config: {
                markdown: true
            }
        },
        /*shaped_array: {
            title: "My shaped array",
            dataType: "array",
            config: {
                field: CustomShapedArrayField,
                preview: CustomShapedArrayPreview,
                customProps: {
                    properties: [
                        buildProperty({
                            dataType: "string",
                            title: "Name"
                        }),
                        buildProperty({
                            dataType: "number",
                            title: "Age"
                        })
                    ]
                }
            }
        },*/
        /*actions: {
            title: "Actions",
            description: "Possible user actions",
            validation: { required: true },
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    name: {
                        title: "Name",
                        description: "Text that will be shown on the button",
                        validation: { required: true },
                        dataType: "string"
                    },
                    type: {
                        title: "Type",
                        description: "Action type that determines the user flow",
                        validation: { required: true, uniqueInArray: true },
                        dataType: "string",
                        config: {
                            enumValues: {
                                complete: "Complete",
                                continue: "Continue"
                            }
                        }
                    }
                }
            }
        },*/
        imageUrls: {
            title: "Images",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: (context) => {
                            return "images";
                        },
                        acceptedFiles: ["image/*"],
                        fileName: (context) => {
                            return context.file.name;
                        }
                    }
                }
            }
        },
        status: {
            title: "Status",
            dataType: "number",
            config: {
                enumValues: relaxedStatus
            }
        },
        available_locales: {
            title: "Available locales",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    enumValues: locales
                }
            }
        },
        /*tags: {
            title: "Tags",
            dataType: "array",
            of: {
                dataType: "string"
            }
        },*/
        /*available_dates: {
            dataType: "array",
            title: "Available Dates",
            of: {
                dataType: "timestamp"
            }
        },*/
        /*images: {
            title: "Images",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "images",
                        acceptedFiles: ["image/!*"]
                    }
                }
            }
        },
        image: {
            title: "Image",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "room",
                    acceptedFiles: ["image/!*"]
                }
            }
        },*/
        room_date: {
            title: "room date",
            dataType: "timestamp"
        },
        created_at: {
            title: "Created at",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updated_on: {
            title: "Updated on",
            dataType: "timestamp",
            autoValue: "on_update"
        },
        /*description1: {
            title: "Description1",
            dataType: "string",
            config: {
                multiline: true
            }
        },*/
        /*search_adjacent: {
            title: "Search adjacent",
            dataType: "boolean"
        },
        difficulty: {
            title: "Difficulty",
            dataType: "number"
        },
        range: {
            title: "Range",
            validation: {
                min: 0,
                max: 3
            },
            dataType: "number"
        },*/
        pdf: {
            title: "Pdf",
            dataType: "string",
            config: {
                storageMeta: {
                    storagePath: "room"
                }
            }
        }
    },
    defaultValues: {
        // empty_string: "",
        title: null
    }
});




import React, { ReactElement } from "react";
import { PreviewComponentProps } from "@camberi/firecms";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Nft from "./Nft";

const token = [
    "0x495f947276749ce646f68ac8c248420045cb7b5e",
    "94040561731130154258849466524996382336886165457698652927704144847658972872705",
    "OpenSea",
    "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/94040561731130154258849466524996382336886165457698652927704144847658972872705",
]
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        light: {
            fontSize: "small",
            color: "#838383"
        },
    })
);

export default function NftTokenPreview({
                                                 value, property, size, customProps
                                             }: PreviewComponentProps<number>)
    : ReactElement {

    const classes = useStyles();

    return (
        <Nft contract={customProps.contract} tokenId={customProps.token} service={token[2]} url={token[3]}></Nft>
        /*<div className={value ? undefined : classes.light}>
            {value ? value : "Not available"}
        </div>*/
    );

}

// Replace with your Firebase config
export const firebaseConfigBc = {
    apiKey: "AIzaSyBqrsQnJBCtLRK8m_l3Zvk9wHVjoN2hO2I",
    authDomain: "the-haus-nft-dev.firebaseapp.com",
    projectId: "the-haus-nft-dev",
    storageBucket: "the-haus-nft-dev.appspot.com",
    messagingSenderId: "337970012986",
    appId: "1:337970012986:web:6a6617e98ecddd22b0e603",
    measurementId: "G-T1VC6MXKB6"
};

console.log(process.env);
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
};
